@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.App {
  text-align: center;
  color: black;
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
  margin-top: 1vh;
  margin-left: 1vh;
}

.lower-content{
    /* border: solid 2px blue; */
    /* padding-top: 5vh; */
    font-size: 3vh;
    /* background-color: lightskyblue; */
    min-height: 76vh;
    color: black;

}