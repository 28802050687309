@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.App {
  text-align: center;
  color: black;
}

.App-logo {
    height: 11vh;
    pointer-events: none;
    margin-top: 1vh;
    margin-left: 1vh;
  }
  
  
  .App-header {
    background-color: white;
    min-height: 10vh;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 3.5vh;
    color: black;
    /* border: solid 2px blue; */
    /* margin-bottom: 2vh; */
  }
  
  .Header-title {
    margin-top:3vh;
    margin-bottom: 0;
    margin-left: 8vw;
    /* border: solid 2px blue; */
    font-family: "Inter", sans-serif;
    font-weight: 500;
    /* font-style: italic; */
  }
  .Header-title:hover {
    color: blue; /* Color on hover */
  }

  @media only screen and (max-width: 600px) {
    .App-header {
        flex-direction: column;
    }
  }