.contact {
    font-weight: 600;
    margin-top: 0vh;
    /* border: solid 2px lightblue; */
    height: 40vh;
    width: 20vw;
   color: blue;
}
.contact-container {
    margin-left: 7vw;
    display: flex; 

}
.contact > img {
    height: 30vh;
}