.nav-bar{
    /* border: solid 2px green; */
    min-height: 10vh;
    font-size: 2.5vh;
    display:flex;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    width: 25vw;
    margin-right: 8vw;
   
}
.nav-bar-btn{
    color: black;
    margin-left: 2vw;
    margin-top:1vh;
}
.nav-bar-btn:hover{
    color: blue;
}
@media only screen and (max-width: 600px) {
    .nav-bar {
        width: 90vw;
    }
  }